<template>
  <div class="tag-manage">
    <b-dropdown class="dropdown-tag">
        <template #button-content >
        	<font-awesome-icon v-if="open" :icon="['far', 'times']"/>
        	<font-awesome-icon v-else :icon="['far', 'pencil-alt']"/>
        </template>
        <template v-for="color in colors_dispo">
            <span class="tags text-center" :style="'background-color:'+color" :key="color" @click="selectColor(color)"><font-awesome-icon :icon="['fal', 'plus']"/></span>
        </template>
	</b-dropdown>
	
    <template v-for="color in colors_selected">
        <span class="tags" :style="'background-color:'+color" :key="'selected'+color" @click="deleteColor(color)"><font-awesome-icon :icon="['fal', 'times']"/></span>
    </template>
  </div>
</template>


<script type="text/javascript">
    import Tags from '@/mixins/Tags.js'

	export default {
		name: 'TagsManagement',
		mixins: [Tags],
		props: ['fk', 'type'],
		data () {
			return {
				open: false,
				all_colors: [],
				colors_selected: []
			}
		},
 
		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                this.all_colors = this.getAllColors()

                const tag_color = await this.getTags(this.fk, this.type)
                this.colors_selected = tag_color.map(color => color.tags_color)
			},
			openColors() {
				this.open = !this.open
			},
			async selectColor(color) {
				this.colors_selected.push(color)
				await this.setTags(this.fk, this.type, color)
				this.successToast('toast.info_save_succes')
			},
			async deleteColor(color) {
				// if(!this.open) return false
				let index = this.colors_selected.indexOf(color);
				if (index !== -1) {
					this.colors_selected.splice(index, 1);
				}
				await this.deleteTags(this.fk, this.type, color)
				this.successToast('toast.info_save_succes')
			}
		},

		watch: {
			fk (val) {
				this.init_component()
			}
		},

		computed: {
			colors_dispo() {
				return this.all_colors.filter(color => !this.colors_selected.includes(color))
			}
		}
	}

</script>